<template>
  <TTView>
    <VRow>
      <VCol>
        <VTabs>
          <VTab :to="{ name : Names.R_ENGAGEMENT_REPORTS }">
            Все отчеты
          </VTab>
          <VTab :to="{ name : Names.R_ENGAGEMENT_REPORTS_ARCHIVES }">
            Архивы 360
          </VTab>
        </VTabs>
      </VCol>
    </VRow>
    <RouterView />
  </TTView>
</template>

<script>
export default {
  name: 'Reports',

  inject: ['Names'],
};
</script>
